import { Card, Flex } from '@aws-amplify/ui-react';

const ControlCard = ({ page, view, control, data, type, getControlValues, index, totalLength }) => {
    const controlValues = type ==='Filters' ? data : getControlValues(page, data[page][view][control]);
    const controlKey = type ==='Filters' ?  control : `${page.replace(/ /g, '')}_${control}`

    const getDefaultValues = (page, control, controlKey) =>{
      const defaults = {
        'Quick Facts' : {'Dimensions': 'No selections','Metrics': 'Cases' ,'Calculation Type': 'No Selections'},
        'Void Report' : {'Products': 'ASYS Description','Markets': 'Region, Market' ,'Metrics': 'Cases'},
        'Weekly Trend' : {'Metrics': 'Cases','Dimension': 'Merch Category' ,'Dimensions': 'Merch Category'}
      }
      const filters = defaults[page][control].split(',').map((filter) => 
        <li key={`${controlKey}_key_${filter}`}>{filter}</li>
      );
      return filters
    }
    const getControlRound = () =>{
        //gets fraction part to define what roundness is needed
        const fractionalPart = (totalLength / 3) % 1;
        //flex is divided by groups of 3 - meaning the results from the calculation above allows us to know if when the group is not 3 and how many items there is
        // 0.66 for two items 0.33 for one item
        if (fractionalPart === 0) return;
        if (fractionalPart <= 0.34) return 'total-radius';
        if (fractionalPart <= 0.67) return 'right-radius';
    }

    
    return (

        <div key={`${controlKey}_key`} className={`control-card ${page === 'Filters'? (index+1 === totalLength? getControlRound() :''): ''}`}>
        <div className='control-title'> {control.toUpperCase()}</div>
           {controlValues ? (
              <ul className="control-card-items">
                {controlValues.slice(0, 10).map((dispVal) => (
                  <li key={`${dispVal}_key`}>{dispVal}</li>
                ))}
              </ul>
            ) : (
              <ul className="control-card-items">
                {getDefaultValues(page, control, controlKey)}
              </ul>
            )}
            <p className="control-card-extra-items">
              {controlValues && controlValues.length > 10
                ? `... ${controlValues.length - 10} more values selected`
                : ""}
            </p>

        </div>
    );
}
export default ControlCard;