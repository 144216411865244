import usersService from '../../axios/usersService';
import { validateInputs, checkStatus } from './helpers';


export const handleSaveFiltersInTable = async(
    viewType,
    filters,
    filtersNameInput,
    setFiltersNameInput,
    savedFiltersName,
    setConfig,
    source
) => {

    const operation = 'insert';

    if (source=== "customer-data" || source === "purchase"){
        const isInputValid = validateInputs(filtersNameInput, filters, setConfig, operation, source);
        if (!isInputValid) return
    }
    else if(!Object.values(filters).some(item => Object.keys(item).length > 0)){
        setConfig({variation: "error", msg: "Please select at least 1 filter to save"});
        return
    }


    const saveFiltersResponse = await usersService.insertUserFilters(filtersNameInput, filters, viewType);

    checkStatus(saveFiltersResponse, setConfig, operation);

    if (saveFiltersResponse && saveFiltersResponse.status && saveFiltersResponse.status === "success") {
        savedFiltersName.current = filtersNameInput;
        setFiltersNameInput("");
    }
}


export const handleSharedFiltersSave = async (
    controlData,
    FilterControls,
    view,
    filters,
    viewType,
    filtersNameInput,
    setFiltersNameInput,
    savedFiltersName,
    setConfig
 ) =>{
    let selectedSharedFilters = {}
    Object.keys(controlData).forEach(key => {
      let tempFilterArr = {}
      Object.keys(FilterControls[key][view]).forEach(control =>{
        if (controlData[key].data[FilterControls[key][view][control]]){
          tempFilterArr[FilterControls[key][view][control]] = controlData[key].data[FilterControls[key][view][control]];
        }
      })
      selectedSharedFilters[key] = tempFilterArr;
    })
    selectedSharedFilters["Filters"] = filters;

    handleSaveFiltersInTable(
      viewType,
      selectedSharedFilters,
      filtersNameInput,
      setFiltersNameInput,
      savedFiltersName,
      setConfig,
      'shared-filters'
    )

  };

export const handleGetFiltersFromTable = async(
    viewType,
    setIsDropdownLoading,
    updateSavedFiltersOptions,
    setFiltersNameInput
) => {

    setIsDropdownLoading(true);
    const getFiltersResponse = await usersService.getUserFilters(viewType);
    const { body } = getFiltersResponse;

    if ((!body) || (body && body.zippedRes)) {
        // console.log("no filters available for user");
        updateSavedFiltersOptions(null);
    } else {
        updateSavedFiltersOptions(body);
        // console.log("getFilters test: ", {
        //     body: body,
        //     names: body ? body.map((obj) => obj.name) : []

        // })
    }

    setIsDropdownLoading(false);
    setFiltersNameInput("");
}


export const handleFilterSelectChange = (
    selectedFilterName,
    savedFilters,
    savedFiltersName,
    setFilters,
    setFiltersNameInput,
    filtertype,
    controlData
) => {
    let newFilters = {};
    savedFiltersName.current = selectedFilterName;

    let filterData = savedFilters.filter((savedFilter) => {
        return savedFilter.NAME === selectedFilterName
    })

    filterData = JSON.parse(filterData.pop().FILTERS);
    if (filtertype === "customer-data" || filtertype === "purchase"){
        newFilters = filterData;
    }
    else{
        Object.keys(controlData).forEach(page => {
            controlData[page].setData(filterData[page]);
        })
        newFilters = filterData.Filters;
    }


    setFiltersNameInput("");
    setFilters(newFilters);
}


export const handleDeleteSavedFilter = async(
    viewType,
    savedFiltersName,
    setConfig,
    setFiltersNameInput
) => {

    const operation = 'delete';
    const isInputValid = validateInputs(savedFiltersName.current, null, setConfig, operation);
    if (!isInputValid) return

    const deleteFiltersResponse = await usersService.deleteUserFilters(savedFiltersName.current, viewType);

    checkStatus(deleteFiltersResponse, setConfig, operation);

    if (deleteFiltersResponse && deleteFiltersResponse.status && deleteFiltersResponse.status === "success") {
        setFiltersNameInput("");
        savedFiltersName.current = "";
    }
}
