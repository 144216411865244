import { MenuItem } from '@mui/material';

export const mapSavedFiltersToMenuItems = (savedFilters) => {
  if (!savedFilters || !Array.isArray(savedFilters)) {
    return (
      <p className="opacity-enabled">No filters available</p>
    )
  } else {
    return savedFilters.map((filters) => {
      return (
        <MenuItem
          key={filters.SHARE_CODE}
          value={filters.SHARE_CODE}
        >
          {filters.NAME}
        </MenuItem>
      )
    })
  }
}
