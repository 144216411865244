import { useState, useContext } from 'react';
import { Select, Box, IconButton, Modal } from '@mui/material';
import { Flex, Loader } from '@aws-amplify/ui-react';
import ClearIcon from '@mui/icons-material/Clear';
import usersService from '../../axios/usersService';
import { ExportIconButton } from '../ExportIconButton';
import { FiltersContext } from '../Navigation/Routes';
import { mapSavedFiltersToMenuItems } from './helpers';
import '../../App.css';

export default function SharedProfiles(props) {
  const context = useContext(FiltersContext);
  const { viewType } = context;

  const [shareFilterPageOpen, setShareFilterPageOpen] = useState(false);
  const [savedFilterName, setSavedFilterName] = useState("");
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [savedShareCodes, setSavedShareCodes] = useState([]);
  const [copyMessage, setCopyMessage] = useState("");
  const [submitInput, setSubmitInput] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");
  const [isInputSubmitting, setIsInputSubmitting] = useState(false);


  const handleOpen = () => setShareFilterPageOpen(true);
  const handleClose = () => setShareFilterPageOpen(false);

  const filtertype = props?.page === 'customer-data' || props?.page === 'purchase'
        ? props?.page : viewType;

  const handleGetShareCodes = async(
    setIsDropdownLoading,
    setSavedShareCodes,
  ) => {
    setIsDropdownLoading(true);

    const getShareCodesResponse = await usersService.getUserShareCodes(filtertype);
    const { body } = getShareCodesResponse;

    if ((!body)) {
      setSavedShareCodes(null);
    } else {
      setSavedShareCodes(body);
    }

    setIsDropdownLoading(false);
  }

  const handleSubmitShareCode = async () => {
    setSubmitInput("");
    setIsInputSubmitting(true);

    const submitResponse = await usersService.duplicateFiltersFromShareCode(submitInput, filtertype);
    const { status } = submitResponse;

    if (status === "success") {
      setSubmitMessage("Success!");
      setTimeout(() => setSubmitMessage(''), 5000);
    } else {
      setSubmitMessage("Failed to save filters: ensure code is valid, not previously saved, and is being added on the same mode (Portfolio or Category) that it was originally created on.");
      setTimeout(() => setSubmitMessage(''), 10000);
    }
    setIsInputSubmitting(false);
  }

  const handleCopyToClipboard = (code) => {
    navigator.clipboard.writeText(code)
        .then(() => {
            setCopyMessage('Share code copied to clipboard!');
            setTimeout(() => setCopyMessage(''), 5000);
        })
        .catch(err => {
            setCopyMessage('Failed to copy share code');
            setTimeout(() => setCopyMessage(''), 5000);
        });
  };

  let view;
  if (props?.page === 'customer-data') {
    view = 'Customer';
  } else if (props?.page === 'purchase') {
    view = 'Purchasing';
  } else if (viewType === 'basic') {
    view = 'Portfolio';
  } else if (viewType === 'insights') {
    view = 'Category';
  }

  return (
    <Flex direction={"row-reverse"}>
      <ExportIconButton
        className='save-filters-icon-button'
        onClick={handleOpen}
      />
      <Modal
        className='shared-profiles-popup'
        open={shareFilterPageOpen}
        onClose={handleClose}
        data-testid="modal"
      >
        <Box className='shared-profiles-popup-box'>
          <div className='shared-profiles-popup-close'>
            <IconButton onClick={(handleClose)}>
              <ClearIcon color={"error"} />
            </IconButton>
          </div>
          <div className='shared-profiles-popup-title'>
            <h2 className='popup-title'>Share {view} Filters</h2>
          </div>
          <div className='shared-profiles--profile-selection'>
            <div>
              <div className='shared-profiles-label-container'>
                <label htmlFor="share-code-select">Export Share Code</label>
              </div>
              <div className='shared-profiles-export-input'>
                <Select
                  id="share-code-select"
                  className={`load-filters-select`}
                  defaultValue=""
                  placeholder={savedFilterName}
                  value={savedFilterName}
                  onOpen={() => {
                    handleGetShareCodes(
                      setIsDropdownLoading,
                      setSavedShareCodes,
                    );
                  }}
                  onChange={(event) => {
                    const selected = event.target.value;

                    handleCopyToClipboard(selected);

                    setSavedFilterName(selected);
                  }}>
                  {
                    isDropdownLoading ?
                      <Loader /> :
                      mapSavedFiltersToMenuItems(savedShareCodes)
                  }
                </Select>
                <button className='filters-button-confirm' type="button" onClick={() => handleCopyToClipboard(savedFilterName)} disabled={!savedFilterName}>COPY SHARE CODE TO CLIPBOARD</button>
              </div>
              {copyMessage && <div className="copy-message">{copyMessage}</div>}
            </div>
            <div>
              <div className='shared-profiles-label-container'>
                <label htmlFor="share-code-input">Import From Share Code</label>
              </div>
              <div className='shared-profiles-import-input'>
                <input id="share-code-input" type="text" placeholder="Enter share code" onChange={(e) => setSubmitInput(e.target.value)} />
                <button className='filters-button-confirm' type="submit" onClick={handleSubmitShareCode} disabled={isInputSubmitting}>
                  {isInputSubmitting ? <Loader /> : 'SAVE'}
                </button>
              </div>
              {submitMessage && <div className="submit-message">{submitMessage}</div>}
            </div>
          </div>
        </Box>
      </Modal>
    </Flex>
  );
};
