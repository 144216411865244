/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useContext, useRef } from 'react';
import { encodeDates } from '../utils/dateUtils';
import { FiltersContext } from '../components/Navigation/Routes';
import { Box, IconButton, Modal, Button, CircularProgress } from '@mui/material';
import { Flex } from '@aws-amplify/ui-react';
import FilterConfigMenu from '../components/FilterConfigMenu/FilterConfigMenu';
import ClearIcon from '@mui/icons-material/Clear';
import usersService from '../axios/usersService';
import { postIframeMessage } from '../utils/helpers';
import '../App.css';

export default function CustomAccordion(props) {
    const context = useContext(FiltersContext);
    const {
        viewType,
        isReset,
        savedFiltersName,
        filters,
        impersonatorInfo,
        previousTimeframe, currentTimeframe,
        filterPageOpen, setFilterPageOpen,
        embedRefreshTrigger,setEmbedRefreshTrigger,
        embedDidLoad,
        filterPageData
    } = context;

    const [filterPageSrc, setFilterPageSrc] = useState('');
    const [loadFilterPage, setLoadFilterPage] = useState(false);
    const [filterPageId, setFilterPageId] = useState('');

    // PULL IN ACCOUNT CONTEXT TO GET A USER SESSION AND FETCH EMBEDDING URLS
    const handleOpen = () => setFilterPageOpen(true);
    const handleClose = () => {
        setFilterPageOpen(false);
        setEmbedRefreshTrigger(!embedRefreshTrigger);
    };

    useEffect(() => {
        switch (props?.page) {
            case 'customer-data':
                setFilterPageId('filter-customer');
                break;
            case 'purchase':
                setFilterPageId('filter-purchase');
                break;
            default:
                setFilterPageId(`filter-${viewType}`);
        }

    }, [viewType]);

    useEffect(() => {
        const fetchFilterEmbed = async () => {
            setLoadFilterPage(true);
            const roleInformation = Object.values(impersonatorInfo).length === 0 ? {} : impersonatorInfo;
            const filterPageSrc = await usersService.getEmbeddingUrl(
                filterPageId, // should be changed to value of viewType (basic/insights)
                {},
                roleInformation,
                Object.values(impersonatorInfo).length > 0
            ).then((promise) => {
                return promise;
            });

            setFilterPageSrc(filterPageSrc);

        };

        if (filterPageId) { fetchFilterEmbed() };

    }, [isReset, filterPageId, JSON.stringify(impersonatorInfo), filterPageOpen, savedFiltersName.current, filterPageId]);


    useEffect(() => {
        const sigma_iframe = document.getElementById('filter');

        if (!filterPageOpen || !sigma_iframe) return;

        let selectedFilters = {
            ...filters,
            "previous-timeframe": encodeDates(previousTimeframe),
            "current-timeframe": encodeDates(currentTimeframe)
        };

        if (filterPageData.type === 'loadFilter') {
            postIframeMessage(sigma_iframe, selectedFilters);
        }
    }, [loadFilterPage, JSON.stringify(filterPageData)]);

    return (
        <Flex direction={"row-reverse"}>
            <Button className='filters-button'
                onClick={handleOpen}
                disabled={!embedDidLoad}
                startIcon={!embedDidLoad && <CircularProgress size={20} thickness={5} />}
            >
                { !embedDidLoad? '' : 'FILTER SELECTION'}
            </Button>
            <Modal
                className='embed-popup'
                open={filterPageOpen}
                onClose={handleClose}
                data-testid="modal"
            >
                <Box className='embed-popup-box'>
                    <div className='popup-close'>
                        <IconButton onClick={(handleClose)}>
                            <ClearIcon color={"error"} />
                        </IconButton>
                    </div>
                    <FilterConfigMenu page={props.page} />
                    <iframe
                        title="filter"
                        id="filter"
                        src={filterPageSrc}
                        className="popup-iframe"
                    >
                    </iframe>
                </Box>
            </Modal>
        </Flex>
    );
};
